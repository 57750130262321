/*
      www.OnlineWebFonts.Com 
      You must credit the author Copy this link on your web 
      <div>Font made from <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>is licensed by CC BY 3.0</div>
      OR
      <a href="http://www.onlinewebfonts.com">oNline Web Fonts</a>
*/
@font-face {font-family: "Kingthings Petrock";
  src: url("http://db.onlinewebfonts.com/t/ead11abe45e1b17fa49b0af9b58b6ecb.eot"); /* IE9*/
  src: url("http://db.onlinewebfonts.com/t/ead11abe45e1b17fa49b0af9b58b6ecb.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("http://db.onlinewebfonts.com/t/ead11abe45e1b17fa49b0af9b58b6ecb.woff2") format("woff2"), /* chrome firefox */
  url("http://db.onlinewebfonts.com/t/ead11abe45e1b17fa49b0af9b58b6ecb.woff") format("woff"), /* chrome firefox */
  url("http://db.onlinewebfonts.com/t/ead11abe45e1b17fa49b0af9b58b6ecb.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("http://db.onlinewebfonts.com/t/ead11abe45e1b17fa49b0af9b58b6ecb.svg#Kingthings Petrock") format("svg"); /* iOS 4.1- */
}
body {
  margin: 0;
  font-family: "Averia Serif Libre", cursive;
  background: #0b82c8;
  overflow: hidden;
}

h1 {
  font-family: "Kingthings Petrock";
  font-weight: 400;
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
}
p {
  margin-top: 0px;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#root {
  height: 100%;
  overflow: hidden;
}
.App {
  height: 100%;
  background: #0b82c8;
  background-repeat: no-repeat;
  text-align: center;
  overflow: hidden;
}

@media (max-width: 767px) {
  .App {
    background-image: url("./assets/img/bg-top.svg"),
      url("./assets/img/bg-down.svg");
    /* background-image: url("./assets/img/bg-down.svg"); */
    background-size: contain, contain;
    background-position: top center, bottom center;
  }
}
@media (min-width: 768px) {
  .App {
    background-image: url("./assets/img/bg-lg.svg");
    background-position: center;
  }
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
}

.logo {
  position: fixed;
  left: 30px;
  top: 30px;
}

.island {
  /* position: fixed; */
  /* left: 50%; */
  /* left: 50%; */
  /* top: 40%; */
  /* width: 100%; */
  /* -webkit-transform: translate(-50%, -50%); */
  /* transform: translate(-50%, -50%); */
  text-align: center;
  /* max-height: 100%; */
  /* height: 100%; */
  background: radial-gradient(
    50% 30.91% at 50% 50%,
    #2063a5 0%,
    #0b82c800 100%
  );
  position: relative;
  display: inline-block;
  margin: auto;
}

/* @media (min-width: 768px) { */
.island::after {
  content: "";
  background-image: url("./assets/img/bg-island-2.svg");

  width: 334.44px;
  height: 120.01px;

  background-position: 70% 65%;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  left: 20%;
  background-size: cover;
  transform: translate(-50%, -50%);
  top: 30%;
  z-index: 1;
}
.island::before {
  content: "";
  background-image: url("./assets/img/bg-island-1.svg");
  width: 265.78px;
  height: 116.52px;

  background-position: 70% 65%;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  left: 80%;
  transform: translate(-50%, -50%);
  top: 70%;
  background-size: cover;
  z-index: 1;
}

@media (max-width: 767px) {
  .island::before {
    width: 133.21px;
    height: 58.4px;
  }
  .island::after {
    width: 190.84px;
    height: 68.48px;
  }
}

.island img {
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
  position: relative;
  margin-bottom: -5%;
}

picture {
  display: block;
}

.text {
  width: 100%;
  text-align: center;
  /* position: fixed; */
  left: 0;
  padding: 0 55px 0px 55px;
  bottom: 0;
}

.text h1 {
  color: #fff;
  font-size: 52px;
  margin-top: 0;
  text-shadow: 1px 1px 2px #1375b9, -1px 1px 2px #1375b9, 1px -1px 2px #1375b9,
    -1px -1px 2px #1375b9;
}

.text p {
  max-width: 838px;
  margin-left: auto;
  margin-right: auto;
  font-size: 28px;
  line-height: 34px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 2px #1375b9, -1px 1px 2px #1375b9, 1px -1px 2px #1375b9,
    -1px -1px 2px #1375b9;
}

.social {
  position: fixed;
  right: 20px;
  top: 30px;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}
.social li {
  margin-left: 10px;
  margin-right: 10px;
}
.social li a img {
  background: #0b82c852;
  box-shadow: 0 0 13px 5px #0b82c873;
}
.social li a {
  transition: 0.2s;
  filter: drop-shadow(1px 1px 6px #0b82c8);
}
.social li a:hover {
  /* filter: invert(1); */
}

input {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  border: 0;
  height: 70px;
  width: 100%;
  padding-left: 30px;
  outline: none;
  color: #1a6aa9;
  font-family: inherit;
  font-size: 25px;
}

.form-group {
  position: relative;
  max-width: 661px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
}
.form-group > div > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
input {
  order: 2;
}
.form-group > div > div > div {
  order: 5;
  /* width: 100%; */
  padding-left: 15px;
}

@media (max-width: 767px) {
  .form-group > div > div > div {
    order: 1;
  }
}

button:hover {
  /* opacity: .9; */
}
.form-group button {
  cursor: pointer;
  position: absolute;
  /* left: calc(100% - 50px ); */
  right: -50px;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  background-image: url("./assets/img/submit.png");
  /* height: calc(100% + 20px); */
  border: 0;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  width: 234px;
  color: #0b82c8;
  padding: 0 80px;
  height: 93px;
  top: -11px;
  order: 2;
  font-size: 0;
  font-family: "Averia Serif Libre", cursive;
}
.form-group button::after{
  content: 'Subscribe';
  font-size: 25px;
}
.input::placeholder {
  color: #1a6aa9;
}

/* 
@media (max-width: 575px) {
  .island img {
    max-width: 150%;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
  }
}

@media (max-height: 800px) {
  .text {
    padding-bottom: 30px;
  }
}



@media (max-height: 750px) {
  .island {
    top: 0px;
  }

  .logo {
    top: 20px;
  }
  .text {
    padding-bottom: 20px;
  }

  .text h1 {
    font-size: 24px;
    line-height: 42px;
    margin-bottom: 0;
  }
  .text p {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-height: 800px) and (min-width: 768px) {
  .island {
    top: -10%;
    transform: translateX(-50%);
  }
}

@media (max-width: 375px) {
  .island {
    top: 10px;
  }
  .island img{
    max-width: 120%;
  }
}
@media (max-width: 370px) {
  .island {
    top: 30px;
  }
  .island img{
    max-width: 110%;
  }
} */

@media (max-height: 899px) and (min-width: 768px) {
  .island {
    margin-top: -40px;
  }
  .island img {
    height: 70vh;
  }
  .App {
  }
}

@media (max-height: 999px) and (min-width: 768px) {
  .island {
    margin-top: -50px;
  }
  .island img {
    margin-bottom: -10%;
  }
  .App {
    background-size: cover;
    background-position: top;
  }
}
@media (min-height: 1000px) and (min-width: 768px) {
  .island {
    margin-top: -20px;
  }

  .App {
    background-size: cover;
    background-position: top;
  }
}

@media (min-height: 1200px) and (min-width: 768px) and (max-width: 1100px) {
  .island {
    margin-top: 100px;
  }
  .App {
    background-image: url("./assets/img/bg-top.svg"),
      url("./assets/img/bg-down.svg");
    /* background-image: url("./assets/img/bg-down.svg"); */
    background-size: contain, contain;
    background-position: top center, bottom center;
  }
}
@media (max-width: 767px) {
  input {
    font-size: 16px;
    padding-left: 15px;
    height: 34px;
  }
  .form-group {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form-group button {
    width: 181px;
    padding: 20px 44px 10px;
    display: block;
    position: static;
    height: 72px;
    background-image: url("./assets/img/submit-mobile.png");
    transform: translate(0);

    margin: auto;
  }

  .form-group button::after{
    font-size: 20px;
  }

  .island {
    /* top: 50px; */
    /* transform: translateX(-50%); */
    /* background-image: url("./assets/img/bg.svg"); */
    /* background-repeat: no-repeat; */
    /* background-position: 50% 35%; */
    /* background-size: contain; */
    position: relative;
    transform: translate(0);
    height: auto;
    top: 0;
    left: 0;
  }
  .logo {
    /* position: fixed; */
    /* top: 28px; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    position: static;
    transform: translateX(0);
    left: 0;
    padding-top: 28px;
    margin: auto;
    display: block;
  }
  .text {
    padding: 40px;
    padding-top: 0;
    position: static;
  }
  .text h1 {
    font-size: 24px;
    line-height: 48px;
    margin-bottom: 0;
  }
  .text p {
    font-size: 14px;
    line-height: 17px;
  }

  .social {
    top: auto;
    right: auto;
    position: relative;
    justify-content: center;
    margin-top: 10px;
  }
  .social img {
    width: 30px;
  }

  input {
    height: 38px;
  }
}

@media (max-height: 650px) and (max-width: 400px) {
  .logo {
    padding-top: 10px;
  }
  .island img {
    margin-top: -5%;
    margin-bottom: -10%;
  }
  .form-group {
    margin: 0 auto;
  }
}
@media (max-height: 600px) and (max-width: 400px) {
  .logo {
    padding-top: 5px;
  }
  .island img {
    margin-top: -10%;
    margin-bottom: -12%;
  }
}
